.dashboard {
  max-width: 1200px;
  max-height: 100vh;
  overflow: auto;
}

.dashboard-header {
  background: linear-gradient(#8ae220, #49b90c);
  height: 18vh;
  border-radius: 25px;
  top: -20px;
  position: relative;
  color: #fff;
  overflow: hidden;
  /* width: calc(100% - 30px); */
}

/* @media(max-width: 900px){
  .dashboard-header {
    width: calc(100% - 40px);
  }
} */

.dashboard-header .overlay {
  position: relative;
  z-index: 1;
  height: 270px;
  width: 100%;
  top: -80px;
  background: url("../../../../images/headerCircles.svg") center no-repeat;
  background-size: cover;
  opacity: 0.5;
}

.dashboard-header .content {
  position: relative;
  top: 35%;
  z-index: 100;
}

.dashboard-header .general-content {
  top: 45%;
}

.dashboard .card {
  border: none;
  border-radius: 15px;
  box-shadow: 0px 3px 7px -2px rgb(228, 228, 228);
  min-height: 150px;
  position: relative;
  margin: 10px 0;
  display: grid;
}

.dashboard .card .card-body {
  margin: auto 0;
}

.dashboard a,
.dashboard a:active,
.dashboard a:focus {
  color: #38b000;
}

.dashboard a:hover {
  text-decoration: none;
  color: #329703;
}

.dashboard .card.acc-info {
  background: linear-gradient(#a9ff87, #4df85b);
  min-height: 275px;
}

.dashboard .card.performance-chart {
  background: linear-gradient(#52e6fa, #5dd4f9);
  min-height: 275px;
}

.dashboard .homeLink,
.dashboard .homeLink:active,
.dashboard .homeLink:hover,
.dashboard .homeLink:focus {
  color: inherit;
}

.dashboard .homeLink h4 {
  text-align: center;

  font-size: 1rem;
  font-weight: bold;
}

.dashboard .performance-chart {
  position: relative;
  cursor: pointer;
}
.dashboard .performance-chart::after {
  content: "";

  position: absolute;

  top: 1rem;
  right: 1rem;

  width: 2rem;
  height: 2rem;

  border-radius: 50%;

  background-image: url("../../../../images/icon-expand.svg");
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 48%;

  cursor: pointer;
  pointer-events: none;
}

.dashboard .performance-chart .c3 svg {
  transform: scaleX(1.1);
}

.agressiveLink:hover p {
  color: #38b000;
  text-decoration: none;
}

.dashboard .back-link {
  display: flex;
  align-items: center;
}

.dashboard .back-link a {
  display: flex;
  align-items: center;
}

.dashboard .back-btn {
  border: 2px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  font-weight: 600;
  color: black;
}

.dashboard .back-btn-word {
  margin-left: 30px;
  font-size: 18px;
  font-weight: 600;
}
