.depositBtns a {
  width: 80% !important;
}
.depositContainer {
  margin-top: 50px;
  margin-bottom: 50px;
}
.depositRight {
  padding-left: 10px;
}
.depositBtns {
  margin-bottom: 10px;
}
.depositBtns a {
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #3cbb01 !important;
  color: #267d01 !important;
  font-size: 21px;
}
.depositBtns a:hover {
  background: #3cbb01 !important;
  color: white !important;
}
.depositBtns.disabled a {
  border: 2px solid #959595 !important;
  color: #959595 !important;
  pointer-events: none;
}
.customTitle {
  margin-top: 0 !important;
}
.depositAmount {
  color: #6d6d6d;
}
.qrcode {
  margin-top: 0;
  margin-bottom: 0;
}
.depositInput {
  width: 200px !important;
}
.depositInput input {
  font-size: 20px !important;
  text-align: center;
}
.depositInput .MuiInput-underline::before,
.depositInput .MuiInput-underline.Mui-focused::after,
.depositInput .MuiInput-underline.Mui-focused::before {
  border-bottom-color: green !important;
}
.depositInput p {
  font-size: 15px;
}
.depositInput .dollarSign p {
  font-size: 20px !important;
  color: inherit !important;
}
.tabContainer {
  background: transparent !important;
  box-shadow: none !important;
  width: 50% !important;
  margin: 50px auto;
}
.tabItems {
  background-color: #e9eae9;
  border-radius: 30px;
  min-height: 40px !important;
}
.tabItems button {
  outline: none !important;
}
.tabBtn {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: 40px !important;
}
.tabBtn.Mui-selected {
  background-color: #3ab501;
  border-radius: 30px;
}
.tabBtn.Mui-selected span {
  color: white;
}
.tabBtn span {
  font-size: 15px !important;
  font-weight: 600;
}
.tabBtn.Mui-disabled span {
  color: lightgray !important;
}
.MuiTabs-indicator {
  display: none !important;
}
.tabContent {
  background-color: white;
}
.tabContent h2 {
  color: black;
}
.descDeposit {
  margin-top: 50px !important;
  font-size: 16px;
}
.carouselContainer {
  width: 70%;
  margin: auto;
  margin-top: 30px;
}
.cardLink div {
  display: flex;
  justify-content: center;
}
.carouselDots button {
  background: transparent !important;
}
.cardSliderTitle {
  margin-top: 50px;
  margin-left: 20px;
  color: black;
}
.cardSlider {
  margin-top: 50px;
}
.cardSlider .slick-slide div {
  margin-left: 10px;
  margin-right: 10px;
}
.cardSlider .slick-slide a {
  cursor: pointer;
}
.cardSlider .slick-slide a:focus {
  outline: none;
}
.cardSlider .slick-slide img {
  width: 100%;
}
.depositAddCard {
  margin-top: 50px;
}
