.selectCurrency {
  margin-bottom: 30px !important;
}
.contributionAmount input {
  padding-left: 30px !important;
}
.addContributionForm p {
  font-size: 17px;
  text-align: center;
}
