.portfolio
  width: 100%
  height: 100%

  @media (min-width: 768px)
    background-color: #fff!important
    display: flex
    justify-content: center

.donut_chart
  padding: 60px 30px
  text-align: center
  font-family: Rubik
  color: #272b22
  display: flex
  align-items: center
  flex-direction: column
  @media (min-width: 768px)
    padding: 40px 0
    background: #fff
    width: 910px
    border-radius: 25px
    box-shadow: 0px 20px 13px rgba(61, 88, 11, 0.035), 0px 8.14815px 6.51852px rgba(61, 88, 11, 0.0274815), 0px 1.85185px 3.14815px rgba(61, 88, 11, 0.0168519)

  .mobile_nav

    nav
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 60px
      width: 300px

      @media (min-width: 768px)
        display: none

      p
        font-family: Rubik
        font-weight: 500
        font-size: 16px

      button
        background: none
        border: none
        color: #272B22
        font-family: Rubik
        font-weight: 500
        font-size: 14px

  &_title
    display: none
    @media (min-width: 768px)
      display: block
      margin-bottom: 20px

    & h2
      font-size: 16px
      font-weight: 500

  .mobile_mode_buttons
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 60px

    @media (min-width: 768px)
      display: none

    button
      display: flex
      justify-content: center
      align-items: center
      width: 30px
      height: 30px
      border-radius: 100%
      background: none
      border: 2px solid #272B22
      outline: none

    span
      width: 197px
      height: 2px
      background-color: #272B22

  .chart
    display: flex

    &_donut
      display: none

      @media (min-width: 768px)
        display: block
        margin: 0 150px

    &_pie
      @media (min-width: 768px)
        display: none

    img
      display: none

      @media (min-width: 768px)
        display: block

  &_legends
    margin-top: 60px
    width: 300px
    margin-bottom: 60px

    @media (min-width: 768px)
      margin-bottom: 30px

    h2
      font-size: 16px
      font-weight: 500

  &_legend
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 15px

    &:last-child
      margin-bottom: 0


    &_info
      display: flex
      align-items: center

      & .dot
        display: none

        @media (min-width: 768px)
          display: flex
          width: 12px
          height: 12px
          border-radius: 100%
          display: inline-block
          margin-right: 10px

    &:nth-child(1) .dot
      background-color: #ffd000

    &:nth-child(2) .dot
      background-color: #ffb700

    &:nth-child(3) .dot
      background-color: #82ff47

    &:nth-child(4) .dot
      background-color: #ffea00

    &:nth-child(5) .dot
      background-color: #d0ea00

  &_button
    background: linear-gradient(180deg, #272b22 0%, #353930 100%)
    box-shadow: 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05),0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)
    border-radius: 50px
    padding: 19px 0
    width: 100%

    @media (min-width: 768px)
      padding: 19px 0
      width: 315px


    & a
      font-family: Rubik
      color: white
      text-decoration: none
      font-size: 16px
      font-weight: 500

