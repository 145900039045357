.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainColor {
  color: #38b000;
}

.black-color {
  color: black;
}

.mainBg {
  background-color: #38b000;
}

.btn-dark {
  background: linear-gradient(#272b22, #353930);
  box-shadow: 0px 3px 7px -2px rgb(184, 184, 184);
  transition: 0.5s all ease-in-out !important;
  -webkit-transition: 0.5s all ease-in-out !important;
}

.btn-dark:hover {
  background: linear-gradient(#353930, #272b22);
}

.debugmenu:hover {
  /* width: 250px; */
  z-index: 500;
}

.closeDegubMenu {
  position: relative;
}

.closeDegubMenu span {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 15px;
  color: black;
  font-weight: 800;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

b {
  font-size: 13px;
}
