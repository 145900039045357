@media only screen and (max-width: 700px) {
  .loginCard {
    padding: 0;
  }
  .loginTwoBtn {
    display: block;
  }
  .loginBtn:last-child {
    margin-top: 5px !important;
  }
  .homeBannerContainer,
  .profile,
  .depoWith,
  .performance,
  .descHome {
    max-width: 80%;
  }
  .depoWithContainer {
    padding: 0 !important;
  }
  .userName h3 {
    text-align: center;
  }
  .profileContainer h4.text-right,
  .profileContainer h1.text-right {
    text-align: left !important;
  }
  .aggreTitle span {
    width: initial !important;
  }
  .settingLink,
  .cryptoValue {
    display: block;
  }
  .settingTitle {
    font-size: 18px;
    padding: 8px;
  }
  .backIcon {
    left: 20px;
  }
  .backIcon span {
    font-size: 20px;
  }
  .upDownWallet {
    display: block;
  }
  .upWallet,
  .downWallet {
    margin: 10px 0;
  }
  .faqIcon {
    right: 20px;
  }
  .currency {
    font-size: 18px;
    margin-bottom: 0;
  }
  .percentage {
    font-size: 15px;
    text-align: left;
    color: #626262;
  }
  .tabBtn span {
    font-size: 15px !important;
    font-weight: 700;
  }
  .cardSliderTitle {
    margin-top: 30px;
  }
  .cardSliderTitle h1 {
    font-size: 19px;
  }
  .cardSliderTitle h4 {
    font-size: 14px;
  }
  .cardSlider {
    margin-top: 30px;
  }
  .withdrawBtn a {
    padding: 10px;
    width: 100%;
  }
  #referrerModal .MuiDialog-paper {
    padding: 10px 5px;
    margin: 0;
    width: calc(100% - 10px);
  }
  .faq {
    padding-left: 30px;
    padding-right: 30px;
  }
  .homeBanner {
    padding-bottom: 260px;
  }
  .profile,
  .performance {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .descHome {
    font-size: 18px !important;
  }
  .profileContainer {
    display: flex;
    align-items: center;
  }
  .profileContainer h4 {
    margin-right: 10px;
  }
  #lineChart {
    width: 400px;
    height: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .profileContainer {
    display: initial;
  }
}
