.kycBtn {
  border-radius: 50px;
  text-transform: uppercase;
}

.failed {
  background-color: #e75858 !important;
}

.success {
  background-color: #4caf50 !important;
}

.init {
  background-color: #30342b !important;
}

.kycButtonContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}
