#referrerModal .MuiDialog-paper {
  background: #67ce0c;
  padding: 30px 20px;
  border-radius: 20px;
}
#alert-dialog-title {
  position: relative;
}
#alert-dialog-title .closeModal {
  position: absolute;
  top: 0;
  font-weight: normal;
  font-size: 27px;
  right: 10px;
  cursor: pointer;
}
#alert-dialog-title h2 {
  font-size: 20px;
  text-align: center;
}
#referrerModal * {
  font-weight: bold;
}
#alert-dialog-description {
  color: black;
  text-align: center;
}
#alert-dialog-description span {
  font-size: 22px;
}
#alert-dialog-description span:first-child {
  font-size: 30px;
}
.referrerForm button {
  outline: none !important;
}
.referrerInput input {
  padding-left: 25px;
}
.referrerInput .MuiInputBase-root {
  padding-right: 0;
}
.referrerInput .Mui-focused,
.referrerInput label {
  color: black !important;
}
.referrerInput label {
  left: 10px;
  color: rgba(0, 0, 0, 0.5) !important;
}
.referrerInput .Mui-focused .MuiOutlinedInput-notchedOutline,
.referrerInput .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
.referrerInput .MuiOutlinedInput-notchedOutline {
  border-radius: 50px;
  border-width: 2px;
}
.referrerInput .MuiOutlinedInput-inputAdornedEnd {
  padding-left: 20px;
}
.referrerInput .MuiInputAdornment-positionStart {
  margin-right: 20px;
}
.referrerForm legend {
  margin-left: 10px;
}
.referrerList {
  margin-bottom: 30px !important;
}
.referrerList .MuiListItem-root {
  padding-left: 10px;
  padding-bottom: 0;
}
.referrerList .MuiListItemText-root span {
  font-size: 15px;
  font-weight: 600 !important;
}
.referrerList .not-registered .MuiListItemText-root span {
  color: rgba(0, 0, 0, 0.35) !important;
}
.referrerList .not-registered .MuiListItemText-root p {
  color: rgba(0, 0, 0, 0.1) !important;
}
.referrerList .MuiListItemText-root p {
  font-size: 13px;
}
.referrerList .MuiListItemAvatar-root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.referrerList .MuiListItemAvatar-root span {
  color: #ccff33;
  font-weight: normal !important;
  font-size: 23px;
}
.referrerList .not-registered .MuiListItemAvatar-root span {
  color: rgba(204, 255, 51, 0.2);
}
.descModal {
  text-align: center;
  margin-top: 100px;
}
.submitReferrer {
  display: flex;
  justify-content: center !important;
}
.submitReferrer button span {
  color: white !important;
}
.submitReferrer button {
  background: black !important;
  padding: 10px 50px;
  border-radius: 30px;
  max-width: 400px;
  text-transform: none;
}
