body * {
  font-family: "Rubik"!important;
}
body .material-icons {
  font-family: "Material Icons" !important;
}
.homeTitle {
  color: white;
}
.camelcase {
  text-transform: capitalize;
}
.contentCenter {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.alertDiv {
  font-size: 13px !important;
}
.alertDiv {
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 10px auto !important;
}
.toastDiv .MuiAlert-message {
  font-size: 15px !important;
}
.loadingDiv {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.loadingDiv svg.MuiCircularProgress-svg {
  color: #63cb0b;
}
.loadingWhole {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1;
}
.loadingWhole .MuiCircularProgress-root {
  position: absolute;
  top: 20%;
  left: 48%;
}
.loaderContainer {
  position: relative;
}
.loaderContainer img {
  position: absolute;
  top: 30px;
  left: 25px;
  color: #6bd00d;
  width: 50px;
}
.loaderCircle svg circle {
  color: #00e600;
}
.smallTitle {
  font-size: 18px !important;
  font-weight: 600;
  color: darkgray !important;
}
.loadingContainer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    border: 4px solid hsl(100, 7%, 83%);
    border-top-color: rgba(56, 176, 0, 0.6);
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: spin 1s linear infinite;
  }
}

.mainContent img {
  width: 200px !important;
  height: 100px !important;
}
.unDecoration {
  text-decoration: none !important;
  color: initial !important;
  width: 100%;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #38b000 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #38b000 !important;
}
.MuiButton-containedPrimary {
  background-color: #38b000 !important;
}
.MuiCircularProgress-colorPrimary {
  color: #38b000 !important;
}
.bannerImage {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(-1);
}
.reactLoading {
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}