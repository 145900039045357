.chart {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.backgroundChart {
  background-color: #56e0fa !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.chart h4,
.chart h3 {
  margin: 10px !important;
}
.chart h4:first-child {
  margin-top: 20px !important;
}
.backHome {
  position: absolute;
  top: 70px;
  left: 100px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0;
  border: 3px solid black;
  border-radius: 50%;
}
.backHome span {
  font-size: 28px;
  font-weight: 700;
  color: black;
}
.help span {
  font-size: 40px;
  font-weight: 500;
  color: black;
}
.help {
  position: absolute;
  display: flex;
  justify-items: center;
  align-items: center;
  top: 10px;
  right: 0;
}
