.loginContainer {
  padding-top: 80px;
  font-size: 25px !important;
  max-width: 700px;
}
.loginLogo {
  margin-top: 50px;
  margin-bottom: 50px;
}
.title {
  margin-bottom: 50px !important;
}
.loginCard {
  padding: 10px 20px;
}
.formLabel {
  font-size: 20px !important;
}
.profileForm {
  max-width: 400px;
  margin: auto;
  margin-bottom: 50px;
}
.emailInput {
  width: 100%;
  font-size: 18px !important;
}
.emailInput label {
  font-size: 18px !important;
  margin-left: 15px;
}
.emailInput input {
  font-size: 18px !important;
  padding: 18.5px 30px;
}
.emailInput p {
  font-size: 13px;
}
.emailInput span {
  font-size: 13px !important;
}
.emailInput fieldset {
  border-radius: 30px;
  padding: 0 23px;
}
.loginBtn {
  font-size: 16px !important;
  font-weight: 800 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}
.extraLink span {
  color: #4caf50 !important;
}
span.blackText {
  color: black !important;
}
.registerLink {
  color: #4caf50 !important;
}
.registerLink:hover {
  color: #4caf50 !important;
}
.rememberMe {
  display: block;
  text-align: left;
  margin: 0;
  width: 100%;
}
.rememberMe span {
  font-size: 15px !important;
}
.rememForget {
  margin-top: 10px;
  display: flex;
  margin-left: 20px;
}
a.forgetPass {
  color: black !important;
  font-size: 15px !important;
  text-decoration: underline;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  text-align: right !important;
}
a.forgetPass:hover {
  color: #4caf50 !important;
}
.loginTwoBtn {
  width: 250px;
  margin: auto;
}
.loginBtn {
  width: 100%;
  padding: 10px !important;
  margin-top: 20px !important;
  border-radius: 30px !important;
}
.loginTwoBtn .loginBtn {
  border: 1px solid lightgray !important;
}
.registerBtn button {
  background: black !important;
}
.registerBtn .material-icons {
  color: #4caf50 !important;
}
.phoneInput input {
  padding-left: 5px;
}
.phoneBefore {
  margin-left: 10px;
}
.emailBefore {
  margin-left: 25px;
}
.phoneBefore span {
  font-size: 18px !important;
}
.emailBefore span {
  font-size: 20px !important;
}
.phoneVerifyInput input {
  padding-left: 30px;
}
