@import '_landing-vars'

.email-input:focus
    border: none
    outline: none

// Globals
section
    padding-top: 50px !important
    padding-bottom: 50px !important

html
    scroll-behavior: smooth

.cursor
    cursor: pointer
.landing

    *
        margin: 0
        padding: 0

    h1
        font-family: $fontSecondary
        font-weight: bold
        text-align: center
        letter-spacing: -0.05em
        color: $colorBlackLight
        font-size: 40px
        line-height: 150%
        margin-bottom: 15px
        position: relative
        width: 335px

        @media (min-width: $tablet)
            width: auto
            font-size: 50px

        @media (min-width: $desktop)
            font-size: 60px

        @media (min-width: 1100px)
            font-size: 70px
            line-height: 120%
            width: 1200px
            margin-bottom: 40px

        span 
            position: relative

            &::before 
                transform: scaleX(0)
                transform-origin: bottom right
                content: " "
                display: block
                position: absolute
                top: 0
                right: 0
                bottom: 0
                left: 0
                inset: 0 0 0 0
                background: hsl(121, 90%, 59%)
                z-index: -1
                transition: transform .3s ease

            &:hover::before 
                transform: scaleX(1)
                transform-origin: bottom left

    h2
        font-family: $fontSecondary
        font-weight: bold
        font-size: 35px
        line-height: 120%
        letter-spacing: -0.03em
        color: $colorBlackLight
        margin-bottom: 100px

        @media (min-width: $desktop)
            font-size: 45px
            line-height: 150%

    h3
        font-family: $fontSecondary
        font-weight: 500
        font-size: 22px
        line-height: 150%
        letter-spacing: -0.04em
        color: $colorBlack
        opacity: 0.8

        @media (min-width: $desktop)
            font-size: 34px
            line-height: 40px

    h4
        font-family: $fontSecondary
        font-size: 17px
        font-weight: 500
        letter-spacing: 0.3em
        text-transform: uppercase
        color: #828282
        margin-bottom: 10px

        @media (min-width: $desktop)
            margin-bottom: 28px

    p
        font-family: $fontPrimary!important
        font-weight: 400
        font-size: 15px
        line-height: 150%
        color: $colorBlack
        opacity: 0.8

        @media (min-width: $desktop)
            font-size: 17px
            line-height: 180%

    a
        color: #000
        text-decoration: none
        outline: none

    img
        user-drag: none
        user-select: none
        -moz-user-select: none
        -webkit-user-drag: none
        -webkit-user-select: none
        -ms-user-select: none

    .container
        margin: 0 auto
        width: 92%

        @media (min-width: $desktop)
            width: 980px

        @media (min-width: 1220px)
            width: 1200px

    // Menu

    body.dark
        background-color: red

    .bar
        width: 100%
        padding: 25px 0
        box-shadow: 0 2px 2px -2px rgba(0,0,0,.2)
        position: fixed
        background-color: #fff
        z-index: 3

        .nav
            display: flex
            align-items: center
            justify-content: space-between
            z-index: 3

            &_logo
                display: flex
                align-items: center

                h2
                    font-family: $fontSecondary!important
                    font-size: 24px
                    font-weight: 500
                    margin-left: 17px
                    margin-bottom: 0

            &_links
                list-style: none
                display: none

                @media (min-width: $desktop)
                    display: flex
                    align-items: center

                a
                    margin-right: 50px
                    outline: none

                    &:last-of-type
                        background-color: #ACE96B
                        padding: 15px 62px
                        border-radius: 30px
                        font-family: $fontPrimary!important
                        font-size: 15px
                        font-weight: 700
                        text-align: center

                    li
                        font-family: $fontPrimary!important
                        font-size: 15px

            &_mobile
                display: flex
                position: relative
                justify-content: center
                align-items: center
                width: 20px
                height: 20px
                cursor: pointer
                transition: all .5s ease-in-out

                @media (min-width: $desktop)
                    display: none

                &_button
                    width: 20px
                    height: 2px
                    // background-color: $colorBlack
                    border-radius: 5px
                    // box-shadow: 0 2px 5px rgba(255,101,47,.2)
                    transition: all .5s ease-in-out

                    &::before, &::after
                        content: ''
                        position: absolute
                        width: 20px
                        height: 2px
                        background: #000
                        border-radius: 5px
                        box-shadow: 0 2px 5px rgba(255,101,47,.2)
                        transition: all .5s ease-in-out

                    &::before
                        transform: translateY(-6px)

                    &::after
                        transform: translateY(6px)

                // Mobile Button Animation
                &.open .nav_mobile_button
                    transform: translateX(-50px)
                    background: transparent
                    // box-shadow: none

                    &::before
                        transform: rotate(45deg) translate(35px, -35px)

                    &::after
                        transform: rotate(-45deg) translate(35px, 35px)

                // Mobile Menu Dropdown
                &_menu
                    position: fixed
                    width: 100%
                    height: 100%
                    overflow: hidden
                    background: #fff
                    z-index: -1
                    padding-top: 50px
                    transform: translateY(-110%)
                    transition: all .5s ease-in-out

                    &.open
                        transform: translateY(0%)

                    &_links
                        position: relative
                        display: flex
                        flex-direction: column
                        justify-content: center
                        align-items: center

                        a
                            margin-bottom: 50px

                            &:last-child
                                margin-bottom: 0

                                & li
                                    background-color: #ACE96B
                                    padding: 20px 62px
                                    border-radius: 30px
                                    font-family: $fontPrimary!important
                                    font-size: 15px
                                    font-weight: 700
                                    text-align: center
                                    color: $colorBlackLight
                                    letter-spacing: -0.02e

                            li
                                font-family: $fontPrimary!important
                                font-size: 15px
                                outline: none
                                color: #000

                        &_login
                            width: 50%


    // Hero

    .hero
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        p
            width: 319px
            max-width: 689px
            margin-bottom: 30px
            text-align: center

            @media (min-width: $tablet)
                width: 550px

            @media (min-width: $desktop)
                width: auto

        button
            background-color: #ACE96B
            padding: 14px 0
            border-radius: 30px
            font-family: $fontPrimary!important
            font-size: 17px
            font-weight: 700
            margin-bottom: 40px
            width: 240px
            text-align: center

        &_image
            margin-top: 30px
            width: 382px

            @media (min-width: $desktop)
                width: auto

        &_careers_img
            margin-top: 100px
            display: grid
            grid-template-columns: 38% 58%
            grid-template-rows: 197px 87.94px 88.88px
            column-gap: 15px
            row-gap: 15px

            @media (min-width: $desktop)
                grid-template-columns: 31% 31% 31%
                grid-template-rows: 301px 179px
                column-gap: 30px
                row-gap: 30px
                width: 100%

            @media (min-width: 1200px)
                grid-template-columns: 33% 33% 33%

            img
                height: 100%
                width: 100%
                object-fit: cover

            img:nth-child(1)
                grid-row-start: 2
                grid-row-end: span 2
                @media (min-width: $desktop)
                    grid-row-start: span 2

            img:nth-child(2)
                grid-column-start: span 2
                @media (min-width: $desktop)
                    grid-column-start: span 2


    // Why Us

    .why_us
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        h2
            text-align: center
            width: 330px

            @media (min-width: $desktop)
                width: 600px
                align-self: flex-start
                text-align: left

        .benefits

            @media (min-width: $desktop)
                display: grid
                grid-template-columns: repeat(2, 460px)
                column-gap: 50px
                row-gap: 69px
                margin: 0 auto

            @media (min-width: 1200px)
                column-gap: 160px

            &_card
                margin-bottom: 80px
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                text-align: center

                @media (min-width: $desktop)
                    margin-bottom: 0

                &_img
                    position: relative
                    margin-bottom: 50px
                    // padding-left: 20px

                    &::after
                        position: absolute
                        content: ''
                        background-color: #F2994A
                        opacity: 0.1
                        border-radius: 50%
                        width: 80px
                        height: 80px
                        left: -23px
                        top: -21px

                    img
                        height: 35px
                        width: 35px
                        min-width: 35px
                        min-width: 35px
                        max-height: 35px
                        max-height: 35px

                &:nth-child(2) .benefits_card_img::after
                    background-color: #BB6BD9

                &:nth-child(3) .benefits_card_img::after
                    background-color: #27AE60

                &:nth-child(4) .benefits_card_img::after
                    background-color: #2D9CDB


                &_text
                    h3
                        margin-bottom: 30px

                        @media (min-width: $desktop)
                            margin-bottom: 10px

                    p
                        font-family: $fontPrimary!important
                        font-weight: 400
                        font-size: 18px
                        line-height: 180%
                        color: $colorBlack
                        opacity: 0.8
                        max-width: 470px
                        margin: 0 auto

    // Starting

    .starting
        h4
            font-family: $fontSecondary
            font-size: 17px
            font-weight: 500
            letter-spacing: 0.3em
            text-transform: uppercase
            color: #828282
            margin-bottom: 10px

            @media (min-width: $desktop)
                margin-bottom: 28px

        h2
            margin-bottom: 14px

        .start
            display: flex
            flex-direction: column-reverse
            align-items: center
            justify-content: center
            margin-top: 150px

            @media (min-width: $desktop)
                flex-direction: row

            &_img img
                width: 280px
                margin-top: 150px

                @media (min-width: $desktop)
                    width: 323px
                    height: 628px
                    margin-top: 0
                    margin-right: 150px

            &_steps

                .start_step
                    display: flex
                    transition: all .5s ease-in-out
                    padding: 30px 0
                    padding-right: 15px
                    margin-left: 63px
                    border-radius: 20px
                    border: 1px solid transparent
                    z-index: 1
                    margin: 30px 0

                    @media (min-width: $desktop)
                        padding-right: 45px

                    &_bar
                        position: relative

                        &::after
                            position: absolute
                            content: ''
                            height: 135px
                            width: 1px
                            top: 91px
                            left: 42px
                            background-color: $colorPrimaryDark
                            z-index: -1

                            @media (min-width: $tablet)
                                top: 91px
                                height: 116px

                            @media (min-width: $desktop)
                                left: 85px
                                top: 110px
                                height: 149px

                        &::before
                            position: absolute
                            content: ''
                            height: 114px
                            width: 1px
                            top: -82px
                            left: 42px
                            background-color: $colorPrimaryDark
                            z-index: -1

                            @media (min-width: $tablet)
                                top: -62px
                                height: 93px

                            @media (min-width: $desktop)
                                left: 85px
                                top: -89px
                                height: 120px

                    &.active
                        background: #FCFCFC
                        box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.08)
                        z-index: 2

                        & .start_step_number
                            background-color: $colorPrimaryDark

                            & p
                                color: #fff

                        & .start_step_text h3
                            color: #000
                            padding-top: 0

                        & .start_step_text p
                            color: #000

                    &_number
                        display: flex
                        align-items: center
                        justify-content: center
                        min-width: 60px
                        min-height: 60px
                        max-width: 60px
                        max-height: 60px
                        margin-right: 18px
                        margin-left: 10px
                        border: 1px solid $colorPrimaryDark
                        border-radius: 100%

                        @media (min-width: $desktop)
                            margin-right: 47px
                            margin-left: 45px
                            min-width: 80px
                            min-height: 80px
                            max-width: 80px
                            max-height: 80px

                        p
                            font-family: $fontSecondary
                            font-weight: 500
                            font-size: 34px
                            color: $colorPrimaryDark
                            position: relative
                            text-align: center
                            width: 100%

                    &_text                        
                        h3
                            margin-bottom: 6px

                        p
                            font-size: 14px

                            @media (min-width: $desktop)
                                font-size: 17px
                                width: 335px

    // Relax

    .relax

        @media (min-width: $desktop)
            display: flex
            justify-content: space-between

        &_text
            width: 100%

        h2
            margin-bottom: 50px

        &_content
            display: flex
            justify-content: space-between

            &_list
                list-style: none

                @media (min-width: $desktop)
                    margin-right: 178px

                &_item
                    margin-bottom: 30px
                    display: flex
                    align-items: center

                    &:last-child
                        margin-bottom: 0

                    &_text
                        margin-left: 41px

                        h3
                            margin-bottom: 10px

                        p
                            opacity: 0.8

                            @media (min-width: $desktop)
                                width: 390px

        &_img
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center

            img
                width: 245px
                margin-top: 50px

                @media (min-width: $desktop)
                    margin-top: 0
                    width: auto

    // Calculate

    .calculate
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        h2
            margin-bottom: 100px

        .calculator
            background: $calculatorBackground
            box-shadow: 0 4px 30px 0 rgba( 0, 0, 0, 0.05 )
            backdrop-filter: blur( 30.0px )
            -webkit-backdrop-filter: blur( 30.0px )
            border: 2px solid #fff
            border-radius: 30px
            display: flex
            flex-direction: column
            padding: 30px 20px
            margin-bottom: 20px
            height: 100%

            @media (min-width: $tablet)
                flex-direction: row
                width: 640px
                height: 393px
                padding: 30px 40px 30px 40px

            @media (min-width: $desktop)
                width: 737px
                padding: 30px 70px 30px 70px

            h4
                font-family: $fontSecondary
                font-weight: 500
                font-size: 15px
                letter-spacing: 0.3em
                text-transform: uppercase
                color: #828282

            .assets
                @media (min-width: $desktop)
                    padding-top: 20px

                h4
                    margin-bottom: 30px

                &_options
                    display: grid
                    grid-template-columns: repeat(2, 80px)
                    column-gap: 30px
                    row-gap: 30px

                    &_item
                        display: flex
                        flex-direction: column

                        input
                            width: 80px
                            height: 80px
                            border-radius: 50%
                            background-color: #fff
                            border: none
                            outline: none
                            margin-bottom: 10px
                            background-repeat: no-repeat
                            background-position: center

                            &.active
                                border: 2px solid #27AE60

                        label
                            font-family: $fontPrimary!important
                            font-weight: 400
                            font-size: 12px
                            letter-spacing: -0.02em
                            color: $colorPrimaryDark
                            text-align: center
                            width: 60px
                            margin: 0 auto

            .amount
                margin-top: 90px

                @media (min-width: $tablet)
                    border-left: 1px solid #fff
                    margin-top: 0
                    margin-left: 60px
                    padding-left: 29px

                @media (min-width: $desktop)
                    margin-left: 146px
                    padding-top: 20px


                h4
                    margin-bottom: 46px

                .money
                    font-family: $fontPrimary!important
                    font-size: 45px
                    font-weight: 700
                    letter-spacing: -0.05em
                    color: #4F4F4F
                    margin-bottom: 24px
                    border-radius: 20px
                    border: 1px solid #fff
                    -webkit-backdrop-filter: blur(10px)
                    backdrop-filter: blur(10px)
                    background-color: rgba(234, 234, 234, 0.7)
                    padding: 0 13px
                    width: 260px

                .capital
                    font-family: $fontPrimary!important
                    font-size:  15px
                    font-weight: 400
                    line-height: 150%
                    color: 0.8%
                    color: #828282
                    margin-top: 20px

        .result
            background: $calculatorBackground
            border: 2px solid #fff
            border-radius: 30px
            backdrop-filter: blur(5px)
            -webkit-backdrop-filter: blur(5px)
            display: flex
            flex-direction: column
            align-items: center
            padding: 30px 20px

            @media (min-width: $tablet)
                width: 640px
                height: 150px
                padding: 30px 0

            @media (min-width: $desktop)
                width: 737px

            h4
                font-family: $fontSecondary
                font-weight: 500
                font-size: 17px
                letter-spacing: 0.3em
                text-transform: uppercase
                color: #828282
                margin-bottom: 16px

            p
                font-family: $fontSecondary
                font-size: 34px
                font-weight: 500
                color: #BDBDBD
                letter-spacing: -0.04em

                span
                    font-size: 45px
                    font-weight: bold
                    color: $colorPrimaryDark

    // Banks

    .banks

        h2
            text-align: center
            margin-bottom: 100px
            color: $colorBlack

            @media (min-width: $desktop)
                font-size: 34px
                width: 624px
                margin: 0 auto
                margin-bottom: 100px

        &_countrys
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            margin-bottom: 80px

            @media (min-width: $desktop)
                margin-bottom: 111px

            .form-group
                display: flex
                flex-direction: column
                justify-content: flex-start

                label
                    margin-bottom: 5px
                    font-size: 16px
                    font-weight: 400
                    font-family: $fontPrimary!important

                select
                    width: 269px
                    background: #FFFFFF
                    border: 1px solid #AAACAE
                    border-radius: 8px
                    padding: 10px
                    outline: none


        &_list
            @media (min-width: $tablet)
                display: flex
                justify-content: center
                align-items: center
                justify-content: space-evenly

            &_item
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                margin-bottom: 60px

                @media (min-width: $desktop)
                    margin-bottom: 0

                &:last-child
                    margin-right: 0

                &_img
                    img
                        width: 60px
                        height: 60px

                &_info
                    text-align: center

                    &_name
                        font-family: $fontSecondary
                        font-weight: 600
                        font-size: 22px
                        line-height: 26px
                        color: $colorBlack
                        margin: 15px 0!important

                    &_percentaje
                        font-family: $fontSecondary
                        font-weight: 500
                        font-size: 34px
                        line-height: 40px
                        letter-spacing: -0.04em
                        color: $colorBlackLight
                        opacity: 0.6

    // Plan

    .plan
        background: $calculatorBackground
        border-radius: 30px
        margin-bottom: 250px
        padding: 120px 0

        @media (min-width: 1200px)
            padding: 120px 160px
            margin-bottom: 0

        &_header
            margin-bottom: 100px

            @media (min-width: $tablet)
                display: flex
                align-items: center
                justify-content: space-between

            h2
                margin-bottom: 100px

                @media (min-width: $tablet)
                    margin-bottom: 0

            &_buttons
                display: flex

                button
                    font-family: $fontPrimary!important
                    font-weight: bold
                    font-size: 16px
                    text-align: center
                    letter-spacing: -0.500211px
                    color: #19191B
                    border: none
                    outline: none
                    width: 160px
                    height: 55px
                    cursor: pointer

                .active
                    background-color: $colorAccent
                    border-radius: 10px
                    color: #FFFFFF

        &_pricing

            @media (min-width: $tablet)
                display: flex
                justify-content: center
                align-items: center

            &_card
                margin: 0 auto
                width: 330px
                height: 395px
                border: 0.3px solid #AAACAE
                border-radius: 20px
                text-align: center
                padding: 37px 0
                background: #FFFFFF
                box-shadow: 0px 50px 70px rgba(30, 88, 25, 0.05)
                margin-bottom: 100px
                box-sizing: content-box

                @media (min-width: $tablet)
                    margin-bottom: 0


                &_plans
                    font-family: $fontPrimary!important
                    font-weight: 400
                    font-size: 18px
                    letter-spacing: -0.562737px
                    color: #696871

                &_name
                    font-family: $fontSecondary
                    font-weight: 500
                    font-size: 34px
                    letter-spacing: -0.04em
                    color: #1D293F
                    margin: 30px 0

                &_coins
                    list-style: none

                    li
                        margin-bottom: 25px
                        font-family: Mulish
                        font-weight: 400
                        font-size: 17px
                        letter-spacing: -0.531474px
                        color: #696871

                        span
                            font-family: $fontSecondary
                            font-weight: bold
                            font-size: 17px
                            letter-spacing: -0.02em
                            color: $colorBlackLight

                &_button
                    display: inline-block
                    padding: 20px 62px
                    font-family: $fontSecondary
                    font-weight: bold
                    font-size: 15px
                    line-height: 20px
                    letter-spacing: -0.02em
                    color: $colorBlackLight
                    background-color: #ACE96B
                    border-radius: 30px
                    margin-top: 10px
                    box-sizing: content-box

    // Question

    .question
        position: relative
        margin-top: 400px

        @media (min-width: $desktop)
            margin-top: 0
            margin-bottom: 250px

        h4
            font-family: $fontSecondary
            font-weight: 500
            font-size: 17px
            letter-spacing: 0.3em
            text-transform: uppercase
            color: #828282
            margin-bottom: 28px
            line-height: 20px

        h2
            margin-bottom: 30px

            @media (min-width: $desktop)
                margin-bottom: 152px

        &_img
            position: absolute
            right: 0
            top: -665px
            width: 384px

            @media (min-width: $desktop)
                top: 0
                width: auto

        .faq
            &_list
                &_item
                    // width: 1020px
                    background: #F6F6FA
                    border-radius: 30px
                    padding: 30px 30px
                    margin-bottom: 30px
                    transition: all .5s

                    @media (min-width: $desktop)
                        padding: 30px 50px
                        width: 756px

                    @media (min-width: 1200px)
                        padding: 30px 70px
                        width: 1020px

                    &:last-child
                        margin-bottom: 0

                    &_link
                        display: flex
                        justify-content: space-between
                        align-items: center
                        cursor: pointer


                        p
                            width: 200px
                            font-family: $fontSecondary
                            font-weight: 500
                            font-size: 17px
                            letter-spacing: -0.04em
                            color: #000000
                            opacity: 0.5
                            line-height: 22px

                            @media (min-width: $tablet)
                                font-size: 26px
                                line-height: 40px
                                width: auto

                            @media (min-width: $desktop)
                                font-size: 34px

                        img
                            width: 15px

                            @media (min-width: $desktop)
                                width: auto

                        .downarrow
                            transition: all .5s

                            &.active
                                transform: rotate(180deg)

                    &_answer
                        font-weight: 400
                        font-size: 18px
                        line-height: 180%
                        color: #000000
                        max-height: 0
                        overflow: hidden
                        position: relative
                        transition: all 500ms

                        &.active
                            display: block
                            max-height: 200px
                            padding-top: 26px

    // Newsletter

    .newsletter
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 83px 42px 193px 42px
        position: relative
        margin-bottom: 250px

        @media (min-width: $desktop)
            margin-bottom: 0
            padding: 0
            height: 654px

        img
            position: absolute
            left: 0
            bottom: -320px
            width: 250px

            @media (min-width: $desktop)
                top: -260px
                bottom: inherit
                width: auto

        h2
            color: $colorBlack
            margin-bottom: 0

        input
            margin: 50px 0 30px 0
            background: #FFFFFF
            border: 1px solid rgba(0, 0, 0, 0.1)
            box-shadow: 0px 10px 50px rgba(7, 45, 1, 0.1)
            border-radius: 30px
            width: 296px
            padding-left: 42px
            height: 60px
            font-family: $fontPrimary!important
            font-weight: 400
            font-size: 18px
            letter-spacing: -0.02em
            color: #000000
            outline: none

            @media (min-width: $desktop)
                width: 563px

        button
            font-family: $fontPrimary!important
            font-weight: bold
            font-size: 17px
            line-height: 22px
            letter-spacing: -0.02em
            color: #333333
            background: #27AE60
            border-radius: 30px
            padding: 20px 30px
            border: none
            outline: none
            cursor: pointer

    // Footer

    .footer
        padding-top: 67px
        background-color: $footerBackground
        &_info

            @media (min-width: $desktop)
                display: flex
                align-items: flex-start
                justify-content: space-between

            &_cl

                &_logo
                    display: flex
                    align-items: center
                    margin-bottom: 18px

                    img
                        width: 50px
                        height: 43.75px
                        margin-right: 22px

                    h2
                        font-family: $fontSecondary
                        font-weight: 500
                        font-size: 34px
                        letter-spacing: -0.04em
                        color: $colorBlack
                        margin-bottom: 0

                &_copy
                    font-family: $fontPrimary!important
                    font-weight: 400
                    font-size: 15px
                    color: $colorBlack
                    opacity: 0.5

            &_menu
                display: grid
                grid-template-columns: repeat(2, 1fr)
                row-gap: 60px
                column-gap: 40px
                margin-top: 50px

                @media (min-width: $desktop)
                    display: flex
                    justify-content: space-between
                    margin-top: 0
                    width: 50%

            h4
                font-family: $fontSecondary
                font-weight: 500
                font-size: 17px
                letter-spacing: 0.3em
                text-transform: uppercase
                color: #828282
                margin-bottom: 42px

            ul
                list-style: none

                a
                    display: block
                    margin-bottom: 15px

                    &:last-child
                        margin-bottom: 0

                    li
                        font-family: $fontPrimary!important
                        font-weight: 500
                        font-size: 18px
                        letter-spacing: -0.03em
                        text-decoration-line: underline
                        color: $colorBlack

            .social_media
                margin-top: 22px
                display: flex

                a:nth-child(2)
                    margin: 0 25px

        &_trademarks
            background-color: $footerBackground
            margin-top: 154px

            p
                text-align: center
                font-family: $fontPrimary!important
                font-weight: 400
                font-size: 15px
                line-height: 150%
                color: $colorBlack
                opacity: 0.5
                padding: 14px 0


    // Careers
    // Mission

    .mission
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        @media (min-width: $desktop)
            flex-direction: row
            align-items: flex-start

        &_img
            margin-bottom: 50px

            @media (min-width: $desktop)
                margin-right: 50px
                margin-bottom: 0

            @media (min-width: 1200px)
                margin-right: 100px

        &_text
            width: 334px
            text-align: center

            @media (min-width: $desktop)
                width: 350px
                text-align: left

            @media (min-width: 1200px)
                width: 430px

            h2
                margin-bottom: 15px

            p
                font-size: 15px
                line-height: 150%
                color: $colorBlack
                margin-bottom: 22px

                &:last-child
                    margin-bottom: 0

    // Greenbox

    .greenbox
        &_text
            text-align: center

            @media (min-width: $desktop)
                text-align: left

            @media (min-width: 1200px)
                padding-left: 150px

            h2
                width: 285px
                margin: 0 auto
                margin-bottom: 48px

                @media (min-width: $desktop)
                    width: 366px
                    margin: 0
                    margin-bottom: 48px


            p
                width: 336px
                margin: 0 auto
                margin-bottom: 22px

                &:last-child
                    margin-bottom: 0

                @media (min-width: $desktop)
                    width: 900px

                @media (min-width: 1200px)
                    width: 1025px

        &_values
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            margin-top: 147px

            @media (min-width: 1200px)
                height: 450px
                display: grid
                grid-template-columns: repeat(2, 330px )
                row-gap: 50px
                column-gap: 50px

            @media (min-width: 1350px)
                display: flex
                flex-direction: row
                align-items: flex-start
                row-gap: 0
                column-gap: 0

            &_card
                background-color: #fff
                border: 1px solid #333333
                border-radius: 30px
                padding: 40px 32px
                opacity: 0.5
                display: flex
                flex-direction: column
                justify-content: center
                height: 337px
                transition: all .5s
                margin-bottom: 50px

                @media (min-width: $desktop)

                @media (min-width: 1200px)
                    margin-right: 35px

                @media (min-width: 1600px)
                    margin-right: 50px

                &:hover
                    transform: scale(1.1)
                    background-color:#219653
                    opacity: 1
                    border-color: #219653

                &:last-child
                    margin-right: 0
                    margin-bottom: 0

                // &.active
                //     padding: 0 39px
                //     background-color:#219653
                //     opacity: 1
                //     height: 411px
                //     border-color: #219653

                    & .greenbox_values_card_number
                        color: #fff
                        -webkit-text-stroke: 1px white

                    & .greenbox_values_card_title
                        color: #fff

                    & .greenbox_values_card_description
                        color: #fff
                        width: 250px

                &_number
                    font-family: $fontSecondary
                    font-size: 34px
                    font-weight: 500
                    margin-bottom: 12px
                    -webkit-text-stroke: 1px black
                    color: #fff

                    &_hover
                        font-family: $fontSecondary
                        font-size: 34px
                        font-weight: 500
                        margin-bottom: 12px
                        -webkit-text-stroke: 1px white
                        color: transparent

                &_title
                    font-family: $fontSecondary
                    color: #333
                    font-size: 34px
                    line-height: 40px
                    letter-spacing: -0.04em
                    font-weight: 500
                    margin-bottom: 12px

                    &_hover
                        font-family: $fontSecondary
                        color: white
                        font-size: 34px
                        line-height: 40px
                        letter-spacing: -0.04em
                        font-weight: 500
                        margin-bottom: 12px

                &_description
                    font-family: $fontPrimary
                    font-size: 15px
                    line-height: 150%
                    color: #333
                    width: 236px

                    &_hover
                        font-family: $fontPrimary
                        font-size: 15px
                        line-height: 150%
                        color: white
                        width: 236px

    .jobs
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center

        @media (min-width: $desktop)
            text-align: left

        &_list
            &_item
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                cursor: pointer
                width: 335px
                min-height: 60px
                background: #F6F6FA
                border-radius: 30px
                margin-bottom: 30px
                padding: 0 50px

                @media (min-width: $desktop)
                    width: 950px

                @media (min-width: 1200px)
                    width: 1079px

                &:last-child
                    margin-bottom: 0

                p
                    font-family: $fontSecondary
                    font-size: 22px
                    color: #000000
                    letter-spacing: -0.04em
                    opacity: 0.5

                img
                    width: 18px
                    height: 7.5px



// Modal

.modal
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%

.ui.form
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center

    & h3
        color: #000
        font-family: $fontSecondary
        font-weight: 500
        font-size: 22px
        line-height: 150%
        letter-spacing: -0.04em
        opacity: 0.8

        @media (min-width: $desktop)
            font-size: 34px
            line-height: 40px

    & p
        margin: 0 auto
        margin-bottom: 20px!important
        margin-top: 10px!important
        font-size: 15px
        line-height: 150%
        color: #000
        font-family: $fontPrimary!important
        font-weight: 400
        opacity: 0.8

        @media (min-width: $desktop)
            font-size: 17px
            line-height: 180%

    .input-wrapper
        position: relative
        line-height: 14px
        // margin: 0 10px
        display: inline-block

        label
            color: #bbb
            font-size: 11px
            text-transform: uppercase
            position: absolute
            z-index: 2
            left: 20px
            top: 14px
            padding: 0 2px
            pointer-events: none
            background: white
            transition: transform 100ms ease
            transform: translateY(-20px)

        input 
            font-size: 13px
            color:#555
            outline: none
            border: 1px solid #bbb
            padding: 10px 20px
            border-radius: 20px
            position: relative

            &:invalid + label
                transform: translateY(0)
                    
            &:focus
                border-color: #27AE60
                border-radius: 20px

                & + label
                    color:#27AE60
                    transform: translateY(-20px)

    .form-group
        margin-top: 25px

        @media (min-width: $desktop)
            display: flex
            margin-top: 50px

        .input-wrap
            @media (min-width: $desktop)
                margin-right: 10px

                &:last-child
                    margin-right: 0

            input
                margin: 20px 0

                @media (min-width: $desktop)
                    margin: 0
                    width: 175px

    .input-wrap
        label
            font-size: 12px
            font-family: $fontPrimary!important
            font-weight: 400!important
            font-size: 12px
            line-height: 16px
            letter-spacing: -0.02em
            color: #27AE60
            top: -18%
            left: 27px
            right: inherit

        input
            border-radius: 30px
            padding-left: 28px
            text-align: left

            &:focus
                border: 1px solid #27AE60
                box-shadow: 0px 10px 50px rgba(7, 45, 1, 0.1)
                border-radius: 30px

    .email
        margin-top: 20px

// Login Modal

.login_modal.ui.modal
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    width: 322px!important
    height: fit-content!important
    padding: 50px 20px!important
    border-radius: 30px
    background-color: #fff
    height: auto
    top: auto
    left: auto
    bottom: auto
    right: auto

    @media (min-width: $desktop)
        width: 490px!important
        padding: 80px!important

    .email
        margin: 30px 0

    .login-button, .button
        background: #ACE96B
        border-radius: 30px
        padding: 20px 60px
        font-family: $fontPrimary
        font-weight: bold
        font-size: 15px
        line-height: 20px
        letter-spacing: -0.02em
        color: $colorBlackLight


// Waitlist Modal
.waitlist_modal.ui.modal
    display: flex!important
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center

    .join-button
        margin-top: 25px
        width: 100%
        background: #ACE96B
        box-shadow: 0px 10px 50px rgba(7, 45, 1, 0.1)
        border-radius: 30px
        color: #0E1219
        font-family: $fontPrimary!important
        font-weight: bold!important
        font-size: 15px
        line-height: 20px
        letter-spacing: -0.02em
        height: 50px

        @media (min-width: $desktop)
            margin-top: 40px

.waitlist_modal.ui.active.modal
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 22px!important
    width: 322px!important
    height: 487px!important
    border-radius: 30px
    background-color: #fff
    // margin: 0!important

    @media (min-width: $desktop)
        padding: 50px 40px!important
        width: 490px!important
        height: 487px!important

.waitlist_modal.ui.modal>.actions
    padding: 0
    padding-top: 20px

.waitlist_modal.modal
    height: auto
    top: auto
    left: auto
    bottom: auto
    right: auto

// Glowing Circle Effects

.glowing
    position: relative
    width: 750px
    height: 750px

    span
        position: absolute
        top: calc(80px * var(--i))
        left: calc(80px * var(--i))
        bottom: calc(80px * var(--i))
        right: calc(80px * var(--i))

        &::before
            content: ''
            position: absolute
            top: 50%
            left: -8px
            width: 15px
            background-color: #f00
            border-radius: 50%


#particles-js
    position: fixed
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-size: cover
    background-position: 50% 50%
    z-index: -1

.start_step_bar
    position: relative
    &::after
        position: absolute
        content: ''
        height: 149px
        width: 1px
        top: 110px
        left: 85px
        background-color: $colorPrimaryDark
        z-index: -1

    &::before
        position: absolute
        content: ''
        height: 120px
        width: 1px
        top: -89px
        left: 85px
        background-color: $colorPrimaryDark
        z-index: -1

// AboutUs
.about-hero
    background-image: url("./About/AboutUs/img/hero-bg.png")
    background-size: cover
    height: 100vh

.about-content
    position: fixed
    z-index: 1
    top: 7rem
    width: 100%
    height: 50px
    padding-top: 15px

.about-landing-content
    padding-top: 11rem