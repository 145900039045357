.settings_card
    background: #fff
    width: 910px
    padding: 40px 0
    border-radius: 25px
    position: absolute
    left: calc(50% - 910px / 2)
    top: 273px
    text-align: center
    display: flex
    align-items: center
    flex-direction: column
    box-shadow: 0px 2px 3px rgba(61, 88, 11, 0.05)
    font-family: Rubik
    color: #272b22
    text-align: center
    margin-top: 40px

    .settings
        display: grid
        grid-template-columns: repeat(2, 1fr)
        column-gap: 60px
        row-gap: 60px

        &_group
            h2
                font-size: 16px
                font-weight: 500
                font-family: Rubik
                margin-bottom: 23px
                text-align: left

            .setting_row
                display: flex
                align-items: center
                margin-bottom: 23px

                h3
                    font-size: 16px
                    font-weight: 400
                    font-family: Rubik
                    clear: both
                    overflow: hidden
                    margin-left: 23px

                    &:first-child
                        margin-left: 25px

                img
                    width: 18px
                    height: 19px

                .switch 
                    position: relative
                    display: inline-block
                    width: 50px
                    height: 24px
                    margin-left: 100px


                    /* Hide default HTML checkbox */
                    & input 
                        opacity: 0
                        width: 0
                        height: 0


                    .slider 
                        position: absolute
                        cursor: pointer
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        background-color: #ccc
                        -webkit-transition: .4s
                        transition: .4s
                        border-radius: 34px


                        &:before 
                            position: absolute
                            content: ""
                            height: 16px
                            width: 16px
                            left: 4px
                            bottom: 4px
                            background-color: white
                            -webkit-transition: .4s
                            transition: .4s
                            border-radius: 50%


                    input:checked + .slider 
                        background-color: #38B000


                    input:focus + .slider 
                        box-shadow: 0 0 1px #38B000


                    input:checked + .slider:before 
                        -webkit-transform: translateX(26px)
                        -ms-transform: translateX(26px)
                        transform: translateX(26px)

        .logout_button
            display: flex
            justify-content: center
            align-items: center

            a
                padding: 19px 127.5px
                border: 2px solid rgba(39, 43, 34, 0.2)
                border-radius: 50px
                text-decoration: none
                color: #272B22
                font-size: 16px
                font-family: Rubik
                font-weight: 500
