// Colors
$colorPrimary: var(--colorPrimary)
$colorPrimaryDark: var(--colorPrimaryDark)
$colorBlack: var(--colorBlack)
$colorBlackLight: var(--colorBlackLight)
$colorAccent: var(--colorAccent)
$calculatorBackground: var(--calculatorBackground)
$planBackground: var(--planBackground)
$questionColor: var(--questionColor)
$footerBackground: var(--footerBackground)

:root 
  --colorPrimary: #6FC50B
  --colorPrimaryDark: #219653
  --colorBlack: #000
  --colorBlackLight: #2A3037
  --colorAccent: #CA7795
  --calculatorBackground: #e7ebee80
  --questionColor: #828282
  --footerBackground: #F0FCE3

body.dark 
  background: rgb(31, 41, 55)
  --colorPrimary: #6FC50B
  --colorPrimaryDark: #219653
  --colorBlack: #fff
  --colorBlackLight: #f8f8f8
  --colorAccent: #CA7795
  --calculatorBackground: #18141180
  --footerBackground: #18141180

// Fonts
$fontPrimary: 'DM Sans', sans-serif
$fontSecondary: 'Rubik', sans-serif

// Responsive Sizes
$mobile: 375px
$tablet: 768px
$desktop: 1024px