header
  display: none

  @media (min-width: 768px)
    left: 30px
    max-width: 1200px
    height: 173px
    margin: auto
    background: linear-gradient(155.44deg, #9ef01a 0%, #38b000 100%)
    border-radius: 0px 0px 25px 25px
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 20px