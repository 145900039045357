.homeCard {
  box-shadow: 5px 20px 18px 5px rgb(0 0 0 / 20%), 0 15px 10px 5px rgb(0 0 0 / 14%),
    0 1px 15px 5px rgb(0 0 0 / 12%) !important;
  border-radius: 25px !important;
}
.homeBanner {
  position: relative;
  padding-top: 100px;
  padding-bottom: 350px;
}
.banner {
  top: -100px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.homeBannerContainer {
  position: relative;
  max-width: 700px;
  margin: auto;
  background: white;
  padding: 20px;
}
.userName h3 {
  word-break: break-all;
  padding-left: 10px;
  margin-top: 0;
}
.avatarContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.userAvatar {
  width: 100px !important;
  height: 100px !important;
  margin: auto !important;
}
.userAvatar img {
  width: 100%;
  height: 100%;
}
.performBtn {
  position: absolute !important;
  top: 20px;
  right: 20px;
  background: lightgreen !important;
  padding: 5px !important;
}
.performBtn svg {
  width: 20px;
  height: 20px;
  fill: white;
}
.performBtn:hover {
  background: white !important;
}
.performBtn:hover svg {
  fill: lightgreen;
}
.btcValueTitle {
  text-align: center;
  margin-top: 20px;
}
.btcValue h1 {
  font-size: 40px !important;
  font-weight: 700 !important;
  margin: 0;
  text-align: center;
}
.depoWith {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -250px;
}
.depoWithContainer {
  margin-top: 30px !important;
}
.depoWithContainer:first-child {
  padding-right: 30px;
}
.depoWithContainer:last-child {
  padding-left: 30px;
}
.depoWithItem {
  width: 100%;
  display: flex;
  justify-content: center;
}
.depoWithItem a:hover {
  text-decoration: none !important;
}
.depoWithItem a:hover h3 {
  color: #007300 !important;
}
.depoWithItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.depoWithItem h3 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  color: green;
}
.profile,
.performance {
  width: 700px;
  margin: 100px auto;
}
.profileCard {
  padding: 10px 50px;
}
.profileContainer .aggreTitle {
  display: flex;
  align-items: center;
  color: #00aa00 !important;
}
.aggreTitle span {
  display: flex;
  width: 80%;
  flex-direction: row-reverse !important;
  font-size: 40px;
  color: #00aa00 !important;
}
.arrowRight {
  display: flex;
  flex-direction: revert;
}
.arrowUp,
.arrowDown {
  font-size: 30px !important;
}
.headerContent {
  padding-bottom: 0 !important;
}
.arrowUp {
  color: lightgreen !important;
}
.arrowDown {
  color: red !important;
}
.accountBtns {
  padding: 30px 0 10px 0;
}
.accountBtns {
  font-size: 26px;
  font-weight: 600;
  text-align: center !important;
}
.accountBtns a {
  text-decoration: none;
  color: darkgray !important;
}
.accountBtns a:hover {
  text-decoration: none;
}
.performCard {
  position: relative;
  background: #57defa !important;
  padding: 30px 0 !important;
}
.performIn {
  background: #50cfe4 !important;
}
.performIn span {
  color: white !important;
  font-size: 23px;
}
.performIn:hover span {
  color: #50cfe4 !important;
}
.infoCard {
  background: #732dd9 !important;
  padding: 10px 20px;
}
.infoTitle h3,
.infoTitle span,
.info span {
  color: white !important;
}
.infoTitle,
.info {
  padding-bottom: 20px !important;
}
.infoTitle span,
.info span {
  font-size: 18px;
}
.infoTitle span {
  color: rgba(255, 255, 255, 0.62) !important;
}
.info {
  font-weight: 700;
  text-align: right;
}
.bottomSection {
  max-width: 700px;
  margin: auto;
  margin-bottom: 50px;
}
.descHome {
  font-size: 23px !important;
  text-align: center;
  margin: 50px auto 20px auto;
  padding-left: 20px;
  padding-right: 20px;
}
.descHome a {
  color: green;
}
.shareBtn a {
  width: 70% !important;
  background: #2e3229;
  border: 2px solid #2e3229;
  padding: 20px 30px;
  color: white !important;
  font-size: 20px !important;
  border-radius: 40px;
  text-align: center;
  transition: all 0.3s;
}
.shareBtn a:hover {
  text-decoration: none;
  color: #2e3229 !important;
  background: white;
}
#lineChart svg {
  margin-left: -15px;
}
#lineChart svg g path {
  stroke-width: 7px;
}

.transaction-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    padding-bottom: 10px;
  }

  @media (min-width: 700px) {
    flex-direction: row-reverse;
    justify-content: space-between;

    img {
      width: 25px;
      padding-bottom: 0;
    }
  }
}

.portfolio-amount {
  @media (max-width: 700px) {
    font-size: 1.8rem;
  }
}

.hide-mobile {
  @media (max-width: 700px) {
    display: none;
  }
}

.crypto-value {
  @media (max-width: 700px) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    p {
      opacity: 0.5;
    }
    b {
      font-size: 1.8rem;
    }
  }
}
