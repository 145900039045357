.deposit
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    @media (min-width: 1024px)
        background-color: #fff
        box-shadow: 0px 2px 3px rgba(61, 88, 11, 0.05)
        border-radius: 25px
        padding-top: 30px
        padding-bottom: 60px
        margin: 0 auto

    &_tabs
        z-index: -2
        display: flex
        background-color: rgba(39, 43, 34, 0.1)
        border-radius: 25px
        width: 210px
        height: 40px
        margin: 0 auto
        margin-bottom: 30px

        @media (min-width: 1024px)
            width: 448px

        button
            opacity: 0.5
            color: #272b22
            font-size: 14px
            font-weight: 500
            width: 105px
            height: 40px

            @media (min-width: 1024px)
                width: 224.67px

            &.active
                background: linear-gradient(180deg, #38B000 0%, #3FC202 100%)
                box-shadow: 0px 20px 13px rgba(56, 176, 0, 0.1), 0px 8.14815px 6.51852px rgba(56, 176, 0, 0.05), 0px 1.85185px 3.14815px rgba(56, 176, 0, 0.025)
                border-radius: 50px
                color: #fff
                opacity: 1

            &.disabled
                color: #9f9f9f

    &_businessdays
        display: none

        @media (min-width: 1024px)
            display: block
            font-size: 14px
            text-align: center
            color: #272b22
            margin-bottom: 60px

    &_operation
        display: none

        @media (min-width: 1024px)
            display: flex
            align-items: flex-start
            justify-content: center

        &_data
            display: flex
            flex-direction: column
            margin-left: 30px

            label
                font-weight: 500
                font-size: 16px
                color: #272b22
                margin-bottom: 20px

            input
                font-size: 32px
                color: #272b22
                opacity: 0.5
                outline: none
                margin-bottom: 20px

            p
                color: #272b22
                opacity: 0.5
                font-size: 12px
                font-weight: 400
                margin-bottom: 20px

            &_buttons
                display: flex

                &_copy
                    color: #fff
                    font-size: 14px
                    font-weight: 500
                    text-align: center
                    background: linear-gradient(180deg, #272B22 0%, #353930 100%)
                    width: 384px
                    height: 55px
                    outline: none
                    border-radius: 50px
                    margin-right: 10px

                &_share
                    border: 2px solid rgba(39, 43, 34, 0.2)
                    border-radius: 50px
                    width: 55px
                    height: 55px

                    img
                        margin: 0 auto