@tailwind base;
@tailwind components;
@tailwind utilities;

.header__background {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(155.44deg, #9ef01a 0%, #38b000 100%);
}

@media (max-width: 700px) {
  .header__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: -1;
  }
  /* .header__background > * {
    display: none;
  } */
}
