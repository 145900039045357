#navbar
  display: block
  left: 30px
  width: 100%
  margin: 0 auto
  height: 40px
  margin-bottom: 40px
  max-width: 1200px
  margin-top: 160px

  @media (min-width: 1024px)
    margin-top: 0

#navbar > div 
  display: flex
  align-items: center
  justify-content: space-between

#navbar > div > div:nth-of-type(1) 
  order: 1
  align-self: center
  display: flex
  align-items: center

#navbar > div > div:nth-of-type(1) img 
  margin-right: 15px
  width: 35px

#navbar > div > div:nth-of-type(2) 
  width: auto
  height: 40px
  order: 2
  margin-left: auto

#navbar > div > div:nth-of-type(1) span 
  font-family: Rubik
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 19px

#navbar > div > div:nth-of-type(2) 
  display: flex
  align-items: center

#navbar > div > div:nth-of-type(2) div 
  padding: 0 5px
  margin-left: auto

#navbar > div > div:nth-of-type(2) > div:nth-of-type(1) img 
  width: 24px
  height: 24px

#navbar > div > div:nth-of-type(2) > div:nth-of-type(2) a 
  position: relative
  color: white
  padding: 10px 40px 10px 15px
  text-decoration: none
  display: inline-block
  font-size: 14px
  background: linear-gradient(180deg, #272b22 0%, #353930 100%)
  box-shadow: 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)
  border-radius: 50px
  font-family: Rubik

#navbar > div > div:nth-of-type(2) > div:nth-of-type(2) a > img 
  width: 20px
  height: auto
  position: absolute
  right: 7.5%
  top: 50%
  -ms-transform: translateY(-50%)
  transform: translateY(-50%)

#navbar > div > span > a 
  text-decoration: none
  font-family: Rubik
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 17px
  text-align: center
  color: #ffffff

#navbarMobile
  @media (min-width: 700px) 
    display: none 

  display: flex
  align-items: center
  justify-content: space-between
  padding-bottom: 15px

  .config-name 
    display: flex
    width: 55%
    align-items: center
    justify-content: space-between
    span 
      text-transform: capitalize

  .potential
    display: flex
    justify-content: flex-end
    margin: 0
  
  
