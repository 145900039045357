.potential {
  max-width: 700px;
  margin: 50px auto;
}
.backgroundPotential {
  background-color: #6dd10e !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.depoWithPotential {
  position: relative;
}
.depositPotential,
.withdrawPotential {
  text-align: center;
  color: rgba(0, 0, 0, 0.58);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.depositPotential p,
.withdrawPotential p {
  margin: 0;
}
.depositPotential span,
.withdrawPotential span {
  font-size: 20px;
  margin-right: 10px;
}
.depositPotential span {
  color: #b7f008;
}
.withdrawPotential span {
  color: #444a3c;
}
.perYear {
  margin-top: 10px !important;
  padding: 10px 10px !important;
  background-color: black !important;
  color: white !important;
  font-size: 20px !important;
  border-radius: 50px !important;
  min-width: initial !important;
}
.perYear span.material-icons {
  font-size: 25px;
}
.rouletteGraph {
  max-width: 700px;
  margin: auto !important;
}
.rouletteGraph p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px !important;
  font-weight: 600;
}
.roulette {
  height: 150px;
}
.potential canvas {
  margin-top: 50px !important;
}
