.share {
  max-width: 700px;
  margin: 50px auto;
}
.backgroundShare {
  background: #3cbb01;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.shareHeader {
  position: relative;
}
.faqIcon {
  position: absolute;
  top: 10px;
  right: 0;
}
.faqIcon span {
  font-size: 20px;
  font-weight: 700;
}
.shareContent {
  font-size: 20px;
  font-weight: 500;
}
.shareContent p:first-child {
  margin-top: 30px;
}
.titleShare {
  margin-top: 20px;
  font-size: 30px;
}
.titleShare span {
  font-size: 35px;
  font-weight: 700;
}
.descShare {
  margin-top: 80px;
}
.shareContent p:last-child {
  margin-top: 200px;
}
