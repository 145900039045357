.agressive {
  max-width: 700px;
  margin: 50px auto;
}
.agressiveHeader {
  position: relative;
  margin-bottom: 30px;
}
.radioAgressive {
  background: transparent !important;
  margin-bottom: 30px;
}
.backgroundEmpty {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #38b000;
}
.backgroundEmptyOrange {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ff9500;
}
.currencySection {
  padding: 30px 50px;
  max-width: 500px;
  margin: auto;
  font-size: 23px;
  font-weight: 700;
}
.currency {
  text-align: left;
}
.percentage {
  text-align: right;
}
.radioAgressive button {
  outline: none;
}
