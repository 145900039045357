.perfomance_chart 
  position: relative
  background: linear-gradient(167.96deg, #52e6fa 0%, #5dd4f9 100%)
  width: 820px
  padding: 30px 0 60px 0
  border-radius: 25px
  text-align: center
  display: flex
  align-items: center
  flex-direction: column
  box-shadow: 0px 2px 3px rgba(61, 88, 11, 0.05)
  font-family: Rubik
  color: #272b22

  // div > svg
    // transform: scale(1.06)

  &_title h4 
    font-size: 12px
    font-weight: 400


  &_money h2 
    margin: 10px 0 20px 0
    font-size: 24px
    font-weight: 500


  &_earnings h3 
    font-size: 14px
    font-weight: 400


  &_controls 
    background: rgba(39, 43, 34, 0.1)
    border-radius: 20px
    margin-top: 40px
    width: 300px
    display: flex
    justify-content: space-between


    & button
      text-decoration: none
      color: #272b22
      font-size: 12px
      font-weight: 500
      font-family: Rubik
      padding: 10px 20px


      &:nth-child(6) 
        background-color: #272b22
        color: #fff
        box-shadow: 0px 9.99322px 10px rgba(39, 43, 34, 0.15),0px 3.84924px 3.18519px rgba(39, 43, 34, 0.0911111),0px 0.814263px 0.814815px rgba(39, 43, 34, 0.0588889)
        border-radius: 20px


.info 
  margin-top: 80px
  background: #fff
  width: 910px
  padding: 60px 0
  border-radius: 25px
  text-align: center
  display: flex
  align-items: center
  flex-direction: column
  box-shadow: 0px 2px 3px rgba(61, 88, 11, 0.05)
  font-family: Rubik
  color: #272b22


  &_row 
    display: flex
    justify-content: space-between
    align-items: center
    width: 676px
    margin-bottom: 15px


    &:nth-child(5) 
      margin-bottom: 0


    & h3 
      font-size: 14px
      font-weight: 400


      &:nth-child(1) 
        opacity: 0.5


      &:nth-child(2) 
        font-weight: 500


  .featured
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column 

    & h2 
      font-size: 16px
      font-weight: 500
      margin-top: 60px


    &_cards 
      margin-top: 40px
      display: grid
      grid-template-columns: repeat(3, 40px)
      column-gap: 200px
      row-gap: 40px


    &_card h2 
      margin-bottom: 10px
      margin-top: 0


    &_card h4 
      font-size: 12px
      font-weight: 400


.overlay_performance
  width: 820px
  height: 428px
  background-color: rgba(158,171,175,0.8)
  z-index: 2
  cursor: pointer
  margin-left: auto
  margin-right: auto
  left: 0
  right: 0
  text-align: center
  border-radius: 20px

  &_home
    width: 100%
    height: 100%
    background-color: rgba(158,171,175,0.8)
    z-index: 2
    cursor: pointer
    margin-left: auto
    margin-right: auto
    left: 0
    right: 0
    text-align: center
    border-radius: 15px

