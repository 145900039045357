nav.warningList {
  position: relative !important;
  background: transparent !important;
  box-shadow: none;
}
.withdrawContainer {
  max-width: 700px;
  margin: 100px auto !important;
  padding: 0 10px;
}
.iconWarning span {
  font-size: 100px;
  color: #38b000;
}
.listNumber {
  font-size: 25px;
  background: #38b000;
  color: white;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 50%;
}
.listText span {
  margin-left: 20px;
  font-size: 17px !important;
}
.warningList {
  margin-bottom: 50px !important;
}
.withdrawBtn a {
  width: 50%;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  border-radius: 40px;
  font-weight: 600;
  transition: all 0.5s;
}
.continueBtn a {
  border: 1px solid #2c3027;
  color: #2c3027;
}
.continueBtn a:hover {
  color: white;
  background: #2c3027;
  text-decoration: none;
}
.cancelBtn a {
  border: 1px solid #2c3027;
  background: #2c3027;
  color: white;
  margin-top: 20px;
}
.cancelBtn a:hover {
  color: #2c3027;
  background: white;
  text-decoration: none;
}
.copyAddress {
  display: flex;
  justify-content: center;
}
.copyAddress a {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white !important;
  background: black;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 30px;
  border: 2px solid black;
  font-size: 20px;
}
.copyAddress a:first-child {
  width: 70%;
}
.copyAddress a:last-child {
  background: white;
  color: black !important;
  border-color: darkgray;
  font-weight: 800 !important;
}
.withdrawInput {
  max-width: 400px !important;
  display: block !important;
  margin: auto !important;
}
.withdrawInput p {
  font-size: 20px;
}
.withdrawInput input {
  text-align: center;
  font-size: 100px;
  color: #3cbb01;
}
.dollarSign p {
  font-size: 100px !important;
  color: #3cbb01;
}
.withdrawTitle,
.withdrawName {
  margin-top: 20px !important;
  text-align: center;
  font-size: 23px;
}
.fromTitle {
  margin-top: 50px !important;
}
.withdrawTitle {
  font-weight: 700;
}
.downArrow span {
  font-size: 30px !important;
}
.downArrow {
  margin-top: 50px;
  margin-bottom: 30px;
}
.withdrawSubmit {
  margin-top: 50px !important;
  margin-bottom: 30px !important;
  font-size: 20px !important;
  border-radius: 40px !important;
  width: 50%;
  padding: 20px !important;
  color: white !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.withdrawSuccessMessage {
  color: darkgray;
  display: flex;
  text-align: center;
  font-size: 20px;
  width: 50%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: auto;
}
.successOK {
  width: 40%;
  margin: auto;
}
.successOK a {
  padding: 10px !important;
  color: #00aa00 !important;
  border: 1px solid #00aa00;
}
.successOK a:hover {
  background: #00aa00 !important;
  color: white !important;
}
