.phoneInput {
  margin-top: 20px !important;
}
.phoneBefore span:last-child {
  margin-left: 5px;
}
.registerBtn {
  width: 250px !important;
  margin: auto;
  margin-top: 30px;
}
.registerEmail input {
  padding-left: 10px !important;
}
